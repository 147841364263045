$(
    function() {
        app.assignNumberControl();
        app.attachSvgEvents();
        app.setSelectedLink();
        app.addRegisterCheckBoxes();
        app.makeSocialButtons();
        app.addCoverSpan();
        app.setBackUrls();
        app.saveProductListInfo();
        app.topMenu.init();
        app.buildPaginFilter();
        app.wrapMultiInputs();
        app.initACBL();
        app.hamburger.init();
        app.initBMO();
        app.markProductsWithIcons();
    }
);

var app = {
    _VERSION: 2,
    _vars: {
        _reg_Incorrect_Domain_Mdg: '_reg_Incorrect_Domain_Mdg_BMO',
        _reg_Incorrect_Domain_Extra: '@uducat.com, @cottoncandy.com',
        _reg_doNotShow_placeHolder: true,
        v: 7
    },
    messages : {},
    productAvailableQuantityUrl : null,
    _doNotRemoveEmptyMaxQntyForMultipleOrdering: true,
    doBeforeProductFormPost: function(form) {
        $('.cart-red-line').removeClass('_done');
        app.prodDetail.ajaxForm(
            function(data) {
                $('.fa.my-cart span').html(data.cartCount);
                var $div = $('.cart-red-line');
                var url = $('.fa.my-cart').attr('href');
                if ($div.length === 0) {
                    if (app.getLang() === 'en') {
                        $('._').append('<div class="cart-red-line"><div class="fa">Item added to cart. <a href="' + url + '">View cart and check out.</a></div></div>');
                    } else {
                        $('._').append('<div class="cart-red-line"><div class="fa">L\'article a été ajouté au panier. <a href="' + url + '">Voir et vérifier le panier.</a></div></div>');
                    }

                    $('.cart-red-line').click(
                        function() {
                            $(this).removeClass('_done');
                            $('._').removeClass('red-line-shown');
                        }
                    )
                }
                $('body,html').animate({
                    scrollTop: 0
                }, 300);
                setTimeout(
                    function() {
                        $('.cart-red-line').addClass('_done');
                        $('._').addClass('red-line-shown');
                    },
                    300
                );
            }
        );
        return false;
    },
    doAfterInventoryItemSet: function() {
        $('.option-name .qty-available').addClass('disp');
    },
    buildPaginFilter: function() {
        if (app._vars.filterSubCats && app._vars.filterSubCats.length > 0) {
            var html = '<div class="category-filter"><label>' + app.lang.selectWord('Filter by', 'Filtrer par') + ':</label><select><option></option>';
            app._vars.filterSubCats.forEach(
                function(arr) {
                    html += '<option value="' + arr.id + '">' + arr.name + '</option>';
                }
            );
            html += '</select></div>';
            $('.product-list-sort-menu').before(html);
            $('.category-filter select').change(
                function() {
                    var id = $(this).val();
                    app._vars.filterSubCats.forEach(
                        function(arr) {
                            if (arr.id === id) {
                                window.location = arr['url'];
                            }
                        }
                    );
                }
            );
        }
        var $pag = $('.pagination-menu');
        if ($pag.find('a').length > 6) {
            $pag.addClass('visible')
        }
        $('.product-list-top-part').addClass('_done');
    },
    initBMO: function() {
        $('.top .quick-search input').focus(
            function() {
                $('.top .li-search')
                    .addClass('selected');
            }
        );
        $('#not-cat-submit').click(
            function(e) {
                app.sendInfo('NC');
                e.preventDefault();
            }
        );
        $('#pride-custom-submit').click(
            function(e) {
                app.sendInfo('PC');
                e.preventDefault();
            }
        );
		$('#diverse-custom-submit').click(
			function(e) {
				app.sendInfo('DV');
				e.preventDefault();
			}
		);
		$('#bpn-custom-submit').click(
			function(e) {
				app.sendInfo('BPN');
				e.preventDefault();
			}
		);
		$('#eco-friend-custom-submit').click(
			function(e) {
				app.sendInfo('EF');
				e.preventDefault();
			}
		);
        $('.top .li-search')
            .click(
                function(e) {
                    e.stopPropagation();
                }
            );
        $('body, .menu').click(
            function () {
                $('.top .li-search')
                    .removeClass('selected');
            }
        );
        $('.div-cart-table td.description').append('<a class="lnk-remove">' + (app.getLang() === 'en' ? 'Remove' : 'Retirer') + '</a>');
        $('a.lnk-remove').click(
            function(e) {
                $(this).closest('tr').find('.del-form button').click();
                e.preventDefault();
            }
        );
        var $specInstr = $('.special-instr textarea');
        if ($specInstr.length === 1) {
            $specInstr.keyup(
                function() {
                    app.setLData('_specialInstr', $(this).val(), true);
                    app.createCookie('SpecialInstr', $(this).val());
                }
            );
            var specInstr = app.getLData('_specialInstr', true);
            if (specInstr) {
                $specInstr.val(specInstr);
            }
        }

        var $tbl = $('.part-2 .ch-prod-list, .udu__cart-prod-list table.tbl-bordered');
        if ($tbl.length >= 1) {
            $tbl.find('.att-link').addClass('min-mode');
            $tbl.find('.image .att-link iframe,.image .att-link img').wrap('<div class="att-wrapper"></div>');
            $tbl.find('tr').each(
                function() {
                    var $tr = $(this);
                    var $tdq = $tr.find('td.quantity');
                    if ($tdq.length === 1) {
                        $tr.find('.image li.att-link:first-child').append('<span class="qty">' + $tdq.html() + '</span>');
                    }
                }
            )
        }
        var $form = $('form.order-form');
        if ($form.length === 1 && app.getLData('_specialInstr', true)) {
            $form.append('<textarea class="hidden" name="custom[SpecialInstructions]" id="special-instr"></textarea>');
            $('#special-instr').val(app.getLData('_specialInstr', true));
        }

        var $comment = $('.bestSellComments');
        if ($comment.length >= 1) {
            var html = $comment.html().replace(/<a id="/g, '<span id="').replace(/<\/a>/g, '</span>');
            $comment.html(html);
            setTimeout(
                function() {
                    $comment.addClass('_done');
                },
                600
            )
        }
        var $qtyItem = $('#pr_form_id_quantity');
        if ($qtyItem.length === 1) {
            if (app.getLang() === 'en') {
                var minMsg = 'The quantity you have entered is less than the minimum allowed to purchase.';
            } else {
                minMsg = 'La quantité que vous avez entrée est moins de minimum autorisé à acheter.';
            }
            $qtyItem.data('udu-min-qty-msg', minMsg);
        }

        app.addEmailChecking();
    },
    doBeforeSetAvailableQuantity: function() {
        if (!app._vars.productInitIsDone) {
            return false;
        } else {
            return true;
        }
    },
    moveInventory: function() {
        var $prodInv = $('.product-inventory');
        if ($prodInv.length === 0) {
            return false;
        }
        var html = $prodInv.html();
        $prodInv.remove();
        $('div.submit').after('<div class="product-inventory qty-place">' + html + '</div>');
        return true;
    },
    contactUs: function() {
        app.sendInfo('US');
        return false;
    },
    wrapMultiInputs: function() {
        $('.mult-quantity input.number.text, .div-cart-table .tbl-bordered td input[name=quantity]')
            .wrap("<div class='mult-input-wrp div-dummy'></div>");

        $('.mult-input-wrp')
            .prepend('<button class="custom-button btn-minus btn-quant" type="button">-</button>')
            .append('<button class="custom-button btn-plus btn-quant" type="button">+</button>');

        $('.option-name > div').append('<div class="dv-tlt-qty">Quantity</div>');

        $('.div-cart-table td .mult-input-wrp input').change(
            function() {
                app.doCartRecalc($(this).closest('form'));
            }
        );
        $('.div-cart-table td .mult-input-wrp button').click(
            function() {
                app.doCartRecalc($(this).closest('form'));
            }
        );
    },
    doCartRecalc: function($form) {
        $form.submit();
    },
    doCustomRecalcProcessing: function(form) {
        setTimeout(
            function() {
                var $form = $(form);
                $form.addClass('in-progress');
                $.post(
                    $form.attr('action'),
                    $form.serialize(),
                    function (html) {
                        var tblHtml = html.split('<!--_UCTBL~-->')[1];

                        $('._').append('<div id="_UCTBL-SYSTEM" class="hidden"></div>');
                        var $div = $('#_UCTBL-SYSTEM');
                        $div.html(tblHtml);
                        var total = $div.find(' td[colspan=5] .money').html();
                        var $tbl = $('.div-cart-table > table');
                        $tbl.find(' td[colspan=5] .money').html(total);
                        $tbl.find('form.del-form').each(
                            function() {
                                var $this = $(this);
                                var id = $this.find('input[name=cartItemKey]').val();
                                var val = $div.find('input[value=' + id + ']').closest('tr').find('td.subtotal span.money').html();
                                $this.closest('tr').find('td.subtotal span.money').html(val);
                            }
                        );
                        $div.remove();
                        $form.removeClass('in-progress');
                    }
                );
            },
            200
        );
        return false;
    },
    doAfterPrevNextButtonsDone: function() {
        var $btnPlace = $('.prod-nav-buttons').addClass('custom-process _done');
        $('.prod-nav-buttons a:not(.prod-list)')
            .mousemove(
                function() {
                    clearTimeout(app._vars.afterButtonsTimeOut);
                    $btnPlace.removeClass('custom-process');
                }
            );

        $btnPlace.mouseleave(
            function() {
                app._vars.afterButtonsTimeOut = setTimeout(
                    function() {
                        $btnPlace.addClass('custom-process');
                    },
                    500
                );
            }
        );
    },
    initCustomOrders: function(url) {
        app._vars.sendMailUrl = url;
        $('.custom-blue li').click(
            function() {
                var $this = $(this);
                var $blk = $this.closest('.item');
                $this.toggleClass('selected');

                if ($blk.find('.selected.ito__other').length > 0) {
                    $blk.addClass('co__with-other');
                } else {
                    $blk.removeClass('co__with-other');
                }
            }
        );
        $('.custom-blue .item').each(
            function() {
                var $this = $(this);
                var pos = app._getOrderPos($this);
                $this.find('.buttons button').click(
                    function() {
                        var $this = $(this);
                        var $blk = $this.closest('.item');
                        var $dt = $blk.find('.co__input');
                        if ($blk.hasClass('co__req') && $blk.find('.selected').length === 0 && $blk.find('li').length > 0) {
                            app.alertMsg('_You_Did_Not_Select_For_Block');
                            return false;
                        }

                        if ($blk.hasClass('co__req') && $dt.length > 0 && !$dt.val()) {
                            app.alertMsg('_Please_Enter_Date_For_Block');
                            return false;
                        }
                        if ($blk.hasClass('co__with-other') && !$blk.find('textarea.co__other-comment').val()) {
                            app.alertMsg('_Please_Enter_Your_Custom_Variant');
                            return false;
                        }
                        app.goToCustomOrder(pos + 1);
                    }
                );
            }
        );
        $('.custom-order-navi li').click(
            function() {
                var pos = app._getOrderPos($(this));
                app.goToCustomOrder(pos);
            }
        );
        $('.custom-order-navi li.pos-0').addClass('selected');
        $(window).scroll(
            function() {
                app._scrollNavi();
            }
        );
        $('.custom-order-navi').addClass('_done');
        app._scrollNavi();
    },
    _scrollNavi: function() {
        var h = $(window).scrollTop() + 110 + parseInt($('body').css('margin-top'));
        var sDiv = $(document).height() - $(window).scrollTop() - $(window).height();
        var $items = $('.custom-blue .item');
        if (sDiv > 230) {
            var pos = 0;
            $items.each(
                function () {
                    var $item = $(this);
                    var t = $item.offset().top;
                    if (t <= h) {
                        pos = app._getOrderPos($item)
                    }
                }
            );
        } else {
            pos = $('.custom-blue .item').length - 1;
        }

        app._markNaviItem(pos);
    },
    _getOrderPos: function($item) {
        var tmp = String($item[0].className).match(/pos-(\d)/);
        if (tmp && tmp[1]) {
            return Number(tmp[1]);
        }
        return 0;
    },
    _markNaviItem: function(pos) {
        $('.custom-order-navi li.selected')
            .removeClass('selected');

        $('.custom-order-navi li.pos-' + pos).addClass('selected');
    },
    initProduct: function() {
        var $opImgs = $('.product-images.options ul li');
        var $ul = $('.product-images-block ul.ul-prod-img');
        if ($opImgs.length > 0) {
            $ul.html($ul.html() + $('.product-images.options ul').html());
        }
        $('.product-images-block').uduSlider({
            $item: $ul
        });
    },
    sendInfo: function(mode) {
        if (!mode) {
            mode = 'B';
        }
        var email = String($('#email').val()).replace(/ /g, '');
        var phone = String($('#phone').val());
        var name = String($('#name').val());
        var msg = String($('#message').val());

        if ($('#can_funds').attr('checked')) {
            var canFunds = 'Yes';
        } else {
            canFunds = 'No';
        }
        if ($('#us_funds').attr('checked')) {
            var usFunds = 'Yes';
        } else {
            usFunds = 'No';
        }
        if (mode === 'US') {
            if (!name || !email || !msg) {
                app.alert('You did not fill all required fields. Please enter values into Name, Email and Message fields and try again.');
                return false;
            }
        } else if (mode === 'NC') {
            if (!phone || !email || (canFunds === 'No' && usFunds === 'No')) {
                app.alertMsg('_Please_Enter_Value_Email_Phone_Msg_Req');
                return false;
            }
        } else if (mode === 'PC') {
			if (!phone || !email) {
				app.alertMsg('_Please_Enter_Value_Email_Phone_Msg_Req');
				return false;
			}
        } else {
            if (!phone || !email) {
                app.alertMsg('_Please_Enter_Value_Email_Phone_Msg_Req');
                return false;
            }
        }

        var data = {
            email: email,
            msg: msg,
            name: name,
            phone: phone,
            mode: mode,
            canFunds: canFunds,
            usFunds: usFunds,
            items: {

            }
        };
        var pos = 0;
        var $errBlock = '';
        $('.custom-blue .item').each(
            function() {
                var $this = $(this);
                if ($this.hasClass('udu__no-send')) {
                    return true;
                }

                var tlt = $this.find('h4').html();
                if (tlt) {
                    data.items[pos] = {
                        tlt: tlt,
                        vals: {

                        },
                        txt: ''
                    }
                }

                var $li = $this.find('li.selected');
                var $dt = $this.find('input.co__input');
                if (
                    ($this.hasClass('co__req') && $li.length === 0 && $this.find('li').length > 0) ||
                    ($this.hasClass('co__req') && $dt.length > 0 && !$dt.val()) ||
                    ($this.find('.ito__other.selected').length > 0 && !$this.find('textarea.co__other-comment').val())
                ) {
                    $errBlock = $this;
                    app.goToCustomOrder($this.data('pos'));
                    app.utils.setTimeout(
                        function() {
                            app.utils.effects.shakeIt($this);
                            app.utils.setTimeout(
                                function() {
                                    $this.find('button.btn-next').click();
                                },
                                300
                            )
                        },
                        600
                    )
                    return false;
                }

                var num = 0;
                $li.each(
                    function() {
                        data.items[pos]['vals'][num] = $(this).html();
                        num++;
                    }
                );
                var $txt = $this.find('textarea');
                if ($txt.length === 1) {
                    data.items[pos]['txt'] = $txt.val();
                    data.items[pos]['other-title'] = $txt.data('other-title');
                }
                var $dt = $this.find('input.co__input');
                if ($dt.length === 1) {
                    data.items[pos]['txt'] = $dt.val();
                }
                pos++;
            }
        );

        if ($errBlock) {
            return false;
        }

        app.blockWindow(
            true,
            true,
            function () {
                $.post(
                    app._vars.sendMailUrl,
                    {
                        data: JSON.stringify(data)
                    },
                    function(html) {
                        app.alertMsg(
                            '_Bmo_Thank_You_Msg',
                            function() {
                                window.location.reload();
                            }
                        );
                    }
                );
            }
        );

        return false;
    },
    goToCustomOrder: function(pos) {
        var m = parseInt($('body').css('margin-top'));
        var t = $('.custom-blue .pos-' + pos).offset().top;
        $('body, html').animate({scrollTop: t - m - 20});
        app._markNaviItem(pos);
    },
    showSvgEditor: function() {
        var $svgPlace = $('.fld-svg');
        if ($svgPlace.find('iframe').length > 1) {
            $svgPlace.toggleClass('svg-open');
        } else {
            for (var i = 0; i < 100; i++) {
                var $item = $('#attachment-' + i);
                if ($item.length === 1) {
                    svgEditor.callEditor(i, app._vars.svgMode);
                    break;
                }
            }
        }
    },
    doAfterAttachSVGLoaded: function(idx) {
        if ($('.svg-btn-customize').length > 0) {
            return false;
        }
        $('.fld-svg').before('<button class="svg-btn-customize" onclick="app.showSvgEditor()" type="button">' + app.lang.selectWord('Customize', 'Personnaliser') + '</button>')
    },
    markProductsWithIcons: function() {
        var $prods = $('.jcarousel-skin-index li .name, .product-list div.item .name, .product-view');
        if ($prods.length === 0) {
            return false;
        }
        $prods.before('<div class="bmo-icons"></div>');
        var key = '_CATS_2_PRODS';
        var savedProducts = app.getLData(key, true);
        if (!savedProducts || window.admin) {
            app.getInfo(
                'prods-by-attrs',
                {
                    cats: '_DIVERSE_VENDOR|_BEST_SELLER|_ACCESSIBLE_PRODUCTS|_ENV_FRIENDLY|_BRAND_ON_DEMAND|_COMING_SOON'
                },
                function(data) {
                    app._assigIcons(app.setLData(key, JSON.parse(data), true));
                }
            )
        } else {
            app._assigIcons(savedProducts);
        }
    },
    _assigIcons: function (data) {
        var items = {};
        if (app.getLang() === 'en') {
            var titles = {
                _COMING_SOON: 'Coming Soon',
                _BRAND_ON_DEMAND: 'Brand on Demand',
                _BEST_SELLER: 'Best Seller',
                _DIVERSE_VENDOR: 'Diverse Vendor',
                _ACCESSIBLE_PRODUCTS: 'Accesible Product',
                _ENV_FRIENDLY: 'Environmentaly Friendly'
            };
        } else {
            titles = {
                _COMING_SOON: 'Disponible Bientot',
                _BRAND_ON_DEMAND: 'Marque sur demande',
                _BEST_SELLER: 'Le meilleur des soldes',
                _DIVERSE_VENDOR: 'Divers vendeurs',
                _ACCESSIBLE_PRODUCTS: 'Produit accessible',
                _ENV_FRIENDLY: 'Environmentaly Friendly'
            };
        }

        var prodId = null;
        if (app.prodInfo) {
            prodId = app.prodInfo.id;
        }
        for (var i in data) {
            var tmp = data[i].split(',');
            tmp.forEach(
                function(val) {
                    if (!Number(val)) {
                        return true;
                    }
                    if (Number(val) === Number(prodId)) {
                        $('.param-ed2-addOptions .' + i)
                            .addClass('active');
                    }
                    if (!items[val]) {
                        items[val] = $('.name a[href$="-' + val + '\\.html"]');
                    }
                    items[val].each(
                        function() {
                            var $li = $(this).closest('li.item, div.item').addClass('bmo-product');
                            var $div = $li.find('.bmo-icons');
                            $div.append('<span title="' + titles[i] + '" class="' + i + '"></span>');
                        }
                    )
                }
            )
        }

        if (prodId) {
            setTimeout(
                function() {
                    if ($('.param-ed2-addOptions ._BRAND_ON_DEMAND.active').length >= 1) {
                        $('.product-submit-comment.com-1').addClass('disp');
                    } else {
                        $('.product-submit-comment.com-2').addClass('disp');
                    }

                    if (app.prodInfo.minQty > 1) {
                        var $com = $('.product-submit-comment.com-3');
                        if ($com.length > 0) {
                            var html = $com.html();
                            html = html.replace('%QTY%', app.prodInfo.minQty);
                            $com.html(html).addClass('disp');
                        }
                    }
                    app._vars.productInitIsDone = true;
                    app.moveInventory();
                    var $comSoon = $('div._COMING_SOON.active');
                    if ($comSoon.length === 1) {
                        var $prodQntAlt = $('.product-quantity-alternative');
                        if ($prodQntAlt.length === 0) {
                            $('.product-inventory').append('<div class="product-quantity-alternative">' + $comSoon.html() + '</div>')
                        }
                    }

                    var $icons = $('.utu-attr-type-_ICON li');
                    if ($icons.length > 0) {
                        html = '';
                        $icons.each(
                            function() {
                                var $this = $(this);
                                var $img = $this.find('img');
                                var src = $img.attr('src');
                                html += '<div class="custom-icons active" style="background-image: url(' + src + ')">' + $img.attr('title') + '</div>';
                            }
                        );

                        $('.param-ed2-addOptions').append(html);
                    }

                    if (app.prodInfo.multipleOrdering === 'N') {
                        app.setProductAvailableQuantity();
                    }
                },
                100
            );
        }
    },
    doAfterProdListAttributesDone: function($list, savedData) {
        $list.each(
            function() {
                var $this = $(this);
                var $item = $this.closest('.item');
                var prodId = app.prodListAttrs.getIdByHref($this.attr('href'));
                if (savedData[prodId] && savedData[prodId]['_minQty']) {
                    var minQty = savedData[prodId]['_minQty'];
                    var msg = '';
                    if (minQty > 1) {
                        if (app.getLang() === 'en') {
                            msg = 'Minimum Quantity ' + minQty;
                        } else {
                            msg = 'Quantité minimum ' + minQty;
                        }
                        $item.append('<div class="min-order-line">' + msg + '</div>')
                    } else {
                        if (app.getLang() === 'en') {
                            msg = 'No minimum quantity';
                        } else {
                            msg = 'Pas de quantité minimum';
                        }
                        $item.append('<div class="min-order-line no-limit">' + msg + '</div>')
                    }
                }
            }
        );
        $('.item .pl-icons').each(
            function () {
                var $this = $(this);
                var $item = $this.closest('.item');
                var $bmoIcon = $item.find('.bmo-icons');
                $bmoIcon.append($this.html());
            }
        );
        setTimeout(
            function() {
                $('.product-list2 .item').addClass('attr-done');
            },
            500
        );
    },
    doAfterBootStrapLoginCorrected: function() {
        setTimeout(
            function() {
                app.addEmailChecking();
                $('.bootstrap').addClass('_done');
            },
            500
        )
    }

};
app.checkEmailByDomain = function (email) {
    if (!app._vars.regEmailDomain) {
        return true;
    }
    var domains = [];
    app._vars.regEmailDomain.forEach(
        function(domain) {
            if (domain) {
                domains.push(domain);
            }
        }
    );

    if (!domains || domains.length === 0 || !email) {
        return true;
    }

    if (app._vars._reg_Incorrect_Domain_Extra) {
        var tmp = app._vars._reg_Incorrect_Domain_Extra.split(',');
        tmp.forEach(
            function(domain) {
                domain = domain.replace(/ /g, '');
                if (domain) {
                    domains.push(domain);
                }
            }
        )
    }

    var isValid = false;
    domains.forEach(
        function(domain) {
            if (String(email).endsWith(domain)) {
                isValid = true;
                return false;
            }
        }
    );
    return isValid;
};

app.addEmailChecking = function() {
    var $items =  $('input[name="login"], input.ch-domain');
    $items.val('');
    app.getAllJSON(
        function(data) {
            var emailDomain = data['udu_regEmailDomain'];
            if (emailDomain) {
                var tmp = emailDomain.split(',');
                var domains = [];
                tmp.forEach(
                    function(emailDomain) {
                        var domain = emailDomain.replace(/ /g, '').split('@').slice(-1).pop();
                        if (domain) {
                            domains.push('@' + domain);
                        }
                    }
                );
                if (domains.length > 0) {
                    app._vars.regEmailDomain = domains;
                    var placeHolder = app._vars.regEmailDomain.join(', ');
                    var hint = placeHolder;
                    if (domains.length === 1 && placeHolder.substr(0, 1) === '@') {
                        hint = 'user' + placeHolder;
                    }
                    $items.change(
                        function() {
                            var that = this;
                            if (!app.checkEmailByDomain($(this).val())) {
                                app.alertMsg(
                                    app._vars._reg_Incorrect_Domain_Mdg ? app._vars._reg_Incorrect_Domain_Mdg : '_reg_Incorrect_Domain_Mdg',
                                    function () {
                                        app.doFlicker($(that));
                                    },
                                    {
                                        replace:{'%DOMAIN%':  app._vars.regEmailDomain.join(', ')}
                                    }
                                );
                                app.doFlicker($(this));
                            } else {
                                var $tr = $('input[name="email"]').closest('tr').prev();
                                if ($tr.hasClass('error-line')) {
                                    $tr.fadeOut(
                                        function() {
                                            $tr.remove();
                                        }
                                    )
                                }
                            }
                        }
                    )
                }
            }
        }
    );
};

app.punchout = {
    setJSON: function() {
        var data = {shippingInfo: {}, shippingMethod: {}, shippingRates:[]};
        var $inputs = $('form.frm-estimate').find('input, select');
        $inputs.each(
            function() {
                var $this = $(this);
                if ($this.data('id') === 'ship-price') {
					if ($this.attr('checked')) {
                        data['shippingInfo'][$this.attr('name')] = $this.val();
					}
				} else if ($this.attr('type') === 'radio') {
                    var name = $this.attr('name');
                    if ($this.attr('checked')) {
                        if (name === 'FulfillmentType') {
                            app.createCookie('FulfillmentType', $this.val(), 0);
                        } else {
                            var $tr = $this.closest('tr');
                            data['shippingMethod'] = {
                                method: $tr.data('method'),
                                option: $tr.data('option'),
                                service: $tr.data('service'),
                                delivery: $tr.data('delivery'),
                                price: $tr.data('price')
                            }
                        }
                    }

                } else {
                    data['shippingInfo'][$this.attr('name')] = $this.val();
                }
            }
        );

        $('table.shipping-methods tr.option').each(
            function() {
                var $tr = $(this);
                data.shippingRates.push({
                    method: $tr.data('method'),
                    option: $tr.data('option'),
                    service: $tr.data('service'),
                    delivery: $tr.data('delivery'),
                    price: $tr.data('price')
                });
            }
        );

         app.createCookie('Cart_Shipping', JSON.stringify(data), 0);

         if (!$('input.udu__business').prop('checked')) {
             var d = 5;
         } else {
             d = 0;
         }

         $('td.total-price').each(
             function() {
                 var $this = $(this);
                 var price = Number($this.data('ini-price')) + d;
                 $this.html(app.money.toDollar(price));
             }
         )

    },
    init: function() {
        var $inputs = $('form.frm-estimate').find('input, select');

        $inputs.change(
            function() {
                app.punchout.setJSON();
            }
        ).click(
            function() {
                app.punchout.setJSON();
            }
        );

        var FulfillmentType = app.cookies.readCookie('FulfillmentType');
        if (!FulfillmentType) {
            FulfillmentType = 'partial';
        }
        $('input[name=FulfillmentType][value=' + FulfillmentType + ']').attr("checked", true);

        try {
            var data = JSON.parse(app.cookies.readCookie('Cart_Shipping'));

            if (data['shippingInfo']) {
                for (var i in data['shippingInfo']) {
                    var $item = $('input[name=' + i + '], select[name=' + i + ']');
                    if ($item.data('id') === 'ship-price') {
                        if (data['shippingInfo'][i] = '0.00') {
                            $('input.udu__business').prop('checked', true);
                        }
                    } else {
                        $item.val(data['shippingInfo'][i]);
                    }

                }
            }
            if (data['shippingMethod']) {
                $('tr[data-service=' + data['shippingInfo'].service + '] input[type=radio]').prop('checked', true);
            }
        } catch(e) {

        }

        app.punchout.setJSON();
    },
    regMe: function (form) {
        var $form = $(form);
        var url = $form.attr('action');
        app.blockWindow(
            true,
            true,
            function() {
                app.getScript(
                    'main.register.func.js',
                    function() {
                        var res = app.checkRegisterForm(form);
                        $form.find('td.error').closest('tr').remove();
                        if (res) {
                            var addr = $form.find('input[name=shippingAddressLine1]').val();
                            var addr2 = $form.find('input[name=shippingAddressLine2]').val();
                            var city = $form.find('input[name=shippingCity]').val();
                            var county = $form.find('select[name=shippingCountryTerritory]').val();
                            var zip = $form.find('input[name=shippingPostalCode]').val();
                            var data = {
                                email: 'no.email@promobullitstores.com',
                                login: 'no.email@promobullitstores.com',
                                firstName: 'na',
                                lastName: 'na',
                                companyName: 'na',
                                telephone: '',
                                billingFirstName: 'na',
                                billingLastName: 'na',
                                billingCompanyName: 'na',
                                billingEmail: 'no.email@promobullitstores.com',
                                billingTelephone: '',
                                billingAddress1: addr,
                                billingAddress2: addr2,
                                billingCity: city,
                                billingCountryTerritory: county,
                                billingPostalCode: zip,
                                shippingFirstName: 'na',
                                shippingLastName: 'na',
                                shippingCompanyName: 'na',
                                shippingEmail: 'no.email@promobullitstores.com',
                                shippingTelephone: '',
                                shippingAddress1: addr,
                                shippingAddress2: addr2,
                                shippingCity: city,
                                shippingCountryTerritory: county,
                                shippingPostalCode: zip
                            };
                            $.post(
                                url,
                                data,
                                function(html) {
                                    html = html.split('<!----UDU__CHANGE__USER_INFO***-->')[1];
                                    $('#___udu-change-info-result').remove();
                                    $('._').append('<div id="___udu-change-info-result" class="hidden">' + html + '</div>');

                                    var $div = $('#___udu-change-info-result');
                                    var $err = $div.find('div.message,error');
                                    if ($err.length > 0) {
                                        app.alert($err.html());
                                        var errMessage = '';
                                        $div.find('tr').each(
                                            function() {
                                                var $this = $(this);
                                                var $td = $this.find('td.error.message');
                                                if ($td.length === 1) {
                                                    errMessage = $td.html();
                                                } else if(errMessage) {
                                                    var name = $this.find('input, select').attr('name');
                                                    $('form.frm-estimate input[name=' + name + ']')
                                                        .closest('tr')
                                                        .before('<tr><td class="error message" colspan="2">' + errMessage + '</td></tr>');

                                                    errMessage = '';
                                                }

                                            }
                                        );
                                        $('body, html').animate({scrollTop: $('form.frm-estimate').offset().top - 150});
                                    } else {
                                        document.location.reload(true);
                                    }
                                    $div.remove();

                                }
                            )
                        }
                    }
                );
            }
        );

        return false;
    }
};
